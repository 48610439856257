import { INavLink } from "types/navigation-types";

export const AppName = "Content Creator UI";

export const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

// TODO: this needs to come from a .env file
export const currentSDZ = { name: "dev1", realm: "DEV" };
export const customerName = "demo";

// TODO: this needs to come from a .env file
// NOTE: this is critical for Keycloak to allow authentication
export const keyCloakConfig = {
  realm: "maryville",
  clientId: "burst",
  url: "https://auth.burstiq.com",
};

// This sets up the roles needed for navlinks and routing
export const requiredRoles = {
  adminTools: ["admin"],
  dictionary: ["user"],
};

/// This is the actual links for the nav
export const mainLinks: INavLink[] = [
  { title: "Dashboard", path: "/dashboard", icon: "mdi:view-dashboard" },
  {
    title: "Dictionary List",
    path: "/dictionary",
    icon: "mdi:view-dashboard",
  },
  {
    title: "Admin",
    path: "/admin",
    icon: "mdi:wrench-cog",
    requiredRoles: requiredRoles.adminTools,
  },
];
